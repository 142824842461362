import jsonStorageFactory from 'json-storage-factory';

const questionIdsStorageKey = (questionSubjectId: number) => `question-ids-${questionSubjectId}`;
const questionIdsStorage = (key: string) => {
  const storage = jsonStorageFactory<number[]>(() => sessionStorage);

  const save = (data: number[]): void => {
    storage.setItem(key, data);
  };

  const load = (): number[] | undefined => storage.getItem(key, []);

  const remove = (): void => {
    storage.removeItem(key);
  };

  return { save, load, remove };
};

export { questionIdsStorage, questionIdsStorageKey };
