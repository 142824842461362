import $ from 'jquery';
import { adjustIframeOnLoad } from '@/lib/utils';
import { questionIdsStorage, questionIdsStorageKey } from '@/lib/questionIdsStorage';
import { initQuestionSession } from '@/pages/question-sessions';
import Prism from 'prismjs';
import 'prismjs/plugins/line-numbers/prism-line-numbers';
import 'prismjs/plugins/line-numbers/prism-line-numbers.css';
import 'viewerjs/dist/viewer.css';
import Viewer from 'viewerjs';
import mermaid from 'mermaid';

const loadMermaid = async () => {
  const darkMode = document.querySelector('body.body-dark');
  mermaid.initialize({ startOnLoad: false, theme: darkMode ? 'dark' : 'default' });
  await mermaid.run({ querySelector: 'pre[lang="mermaid"] > code' });
};

$(async () => {
  if (document.getElementsByClassName('js-question-content').length) {
    initQuestionSession();
  }

  // iframeのサイズ調整
  const adjustIFrames:HTMLCollectionOf<Element> = document.getElementsByClassName('js-adjust-iframe');
  Array.from(adjustIFrames).forEach((iframe:HTMLIFrameElement) => adjustIframeOnLoad(iframe, 40));

  // シンタックスハイライト
  const highlightElements = document.getElementsByClassName('js-highlight');
  if (highlightElements.length > 0) {
    const isDark = (highlightElements[0] as HTMLElement).dataset.dark === 'true';
    if (isDark) {
      import('prismjs/themes/prism-tomorrow.css');
    } else {
      import('prismjs/themes/prism.css');
    }
    Prism.highlightAll();
  }

  // 検索結果のquestion ids保存
  const storeQuestionIdsElement = document.getElementById('js-store-question-ids');
  if (storeQuestionIdsElement) {
    const key = questionIdsStorageKey(Number(storeQuestionIdsElement.dataset.questionSubjectId));
    const questionIds = JSON.parse(storeQuestionIdsElement.dataset.questionIds) as number[];
    questionIdsStorage(key).save(questionIds);
  }

  const images:HTMLCollectionOf<Element> = document.getElementsByClassName('js-image');
  Array.from(images).forEach((image: HTMLImageElement) => {
    const options: Viewer.Options = {
      navbar: false,
      title: false,
      toolbar: {
        zoomIn: {
          show: true,
          size: 'large',
        },
        zoomOut: {
          show: true,
          size: 'large',
        },
        play: false,
        reset: false,
        oneToOne: false,
        prev: false,
        next: false,
        rotateLeft: false,
        rotateRight: false,
        flipHorizontal: false,
        flipVertical: false,
      },
    };
    const viewer = new Viewer(image, options);
    image.addEventListener('viewed', () => {
      viewer.zoomTo(1, false);
    });
  });

  // mermaid
  if (document.querySelector('pre[lang="mermaid"]')) {
    if ($('.js-mermaid-collapse').length) {
      const { initialCollapse } = $('.js-mermaid-collapse').data();
      if (initialCollapse === 'open') {
        await loadMermaid();
      } else {
        $('.js-mermaid-collapse').on('shown.bs.collapse', loadMermaid);
      }
    } else {
      await loadMermaid();
    }
  }
});
