import $ from 'jquery';
import { adjustIframe } from '@/lib/utils';

const zeroPadding = (value: number) => (`00${value}`).slice(-2);

const updateRemainSecond = (remainSecondSpan: HTMLElement) => {
  let second = parseInt(remainSecondSpan.dataset.second!, 10);
  second = second <= 0 ? 0 : second - 1;
  if (second === 0) {
    window.location.href = remainSecondSpan.dataset.abortUrl!;
    return;
  }

  const minPart = Math.floor(second / 60);
  const secPart = zeroPadding(second % 60);
  remainSecondSpan.textContent = `${minPart}:${secPart}`;
  remainSecondSpan.dataset.second = second.toString();
  if (second <= parseInt(remainSecondSpan.dataset.threshold!, 10)) {
    document.getElementById('js-question-session-remain-bar')!.classList.add('bg-danger');
  }
};

const adjustIFrames = () => {
  const iframes: HTMLCollectionOf<Element> = document.getElementsByClassName('js-adjust-iframe');
  Array.from(iframes).forEach((iframe) => adjustIframe(iframe as HTMLIFrameElement, 40));
};

const highlightCorrectSelections = () => {
  Array.from(document.querySelectorAll<HTMLInputElement>('#js-question-selections input'))
    .filter((input) => input.dataset.correct === 'true')
    .map((input) => Array.from(input.parentElement!.getElementsByTagName('label')))
    .flat()
    .forEach((label) => { label.classList.add('text-info', 'font-weight-bold', 'correct-image-border'); });
};

export const initQuestionSession = (): void => {
  // バック禁止の試験で戻るボタンを押したときに戻す
  if (document.getElementsByClassName('js-cannot-back').length > 0) {
    window.history.forward();
  }

  const remainSecondSpan = document.getElementById('js-question-session-remain-second');
  if (remainSecondSpan) {
    setInterval(() => { updateRemainSecond(remainSecondSpan); }, 1000);
  }

  // collapse内iframeのサイズ調整 (addEventListenerでは検知できないのでjQuery使っている)
  $('.js-iframe-collapse').on('shown.bs.collapse', adjustIFrames);
  $('.js-question-session-correct-collapse').on('show.bs.collapse', highlightCorrectSelections);
};
